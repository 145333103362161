#services {
  .services-container {
    .service {
      min-height: 465px;
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          .tooltip {
            text-decoration: none;
            font-weight: normal;
          }
          .social-login__email-opt-in {
            margin-bottom: 10px;
            #facebook__email-opt-in-register-panel {
              height: 17px;
            }
            .social-login__opt-in-label {
              margin-top: 0px !important;
              padding-left: 5px;
            }
          }
        }
      }
      .book-appt-container {
        .registration__terms,
        .registration__sms-list,
        .registration__email-list {
          width: 100%;
        }
        .registration__terms-text,
        .registration__sms-list-text,
        .registration__email-list-text {
          padding-left: 5px;
        }
        .mobile-phone-fieldset {
          float: right;
          padding: 0px 0px 0px 1px;
          border: 0;
          .appt_phone_code {
            height: 38px;
            width: 25%;
          }
          .appt-book-mobile {
            width: 73%;
          }
        }
      }
    }
    .service {
      .service-head {
        min-height: 120px;
      }
    }
  }
  .service-select {
    .service {
      .service-details {
        min-height: 200px;
      }
    }
  }
}

.appt-book-overlay {
  &.appt-book-datepicker-overlay {
    .overlay-content-container {
      top: 120px;
    }
  }
}
