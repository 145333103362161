// Header Alignment
#navigation {
  .navigation-right {
    .field-menu {
      ul {
        &.menu {
          li {
            &.leaf {
              line-height: 43px;
              height: 43px;
              padding: 2px 10px;
              a {
                &.active {
                  color: $color-darker-grey;
                }
              }
            }
          }
        }
      }
    }
  }
  .outer-wrap {
    .nav-menu {
      margin-left: -110px;
    }
    .navigation-right {
      margin-right: -90px;
    }
  }
}

#footer {
  .footer-bottom {
    .block {
      .field-menu {
        .first {
          .switch-lang-link-ch {
            &.active {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .footer-top {
    .footer-top-messenger {
      .consultant {
        width: 20%;
        display: inline-block;
        font-family: 'HelveticaNeueLTStd55Roman', Arial, Sans-Serif;
        vertical-align: top;
        line-height: 21px;
        margin: 0 10px;
        img {
          width: 21px;
          margin: 0 5px 0 0;
          vertical-align: middle;
        }
      }
    }
    .signup-forms {
      .newsletter-signup {
        label {
          display: inline-block;
          font-family: $base-font-family-roman;
          font-size: 13px;
          line-height: 21px;
          padding: 0;
          text-transform: none;
          vertical-align: top;
          width: 47%;
        }
        .extra {
          .field {
            @include swap_direction(margin, 10px 0 0 0);
            @include swap_direction(padding, 0 13% 0 48%);
            label {
              width: 90%;
            }
          }
        }
      }
    }
  }
}

#main.single {
  .panel {
    select,
    input {
      height: calc(3em - -2px);
    }
  }
  .uneditable-input {
    height: calc(3em - -2px);
  }
}

#header {
  .site-logo-responsive-v1 {
    a {
      display: inline-block;
    }
    .right_logo-link {
      display: inline-block;
    }
  }
}

:lang(zh-hant-e-HK) {
  .product-subheading {
    display: none;
  }
  .spp_page_wrap {
    .module-spp-detail {
      .container {
        .col2 {
          .product-subline {
            font-size: 36px;
            line-height: 1;
            @include swap_direction(margin, 0 0 15px);
            padding-bottom: 0;
            letter-spacing: -0.3mm;
          }
          h1 {
            font-size: 1.17em;
          }
        }
      }
    }
  }
}

:lang(en-e-HK) {
  .product-subline {
    display: none;
  }
}

#quickshop {
  .price2.strike-thru-price {
    display: none;
  }
}

#search-wrapper {
  .results {
    .prod_title_primary {
      font-size: 13px;
    }
  }
}

.p-w-r {
  .pr-no-reviews {
    .pr-snippet {
      display: none;
    }
  }
}
/**
overriding font family for chinese site
**/
:lang(zh-HK) {
  #clinique-cny {
    &.main-clinique-cny {
      font-family: $base-font-family;
    }
  }
}

.popup-offer-v1 {
  .signup {
    h2 {
      margin: 0 0 20px;
    }
    .subheading {
      margin: 30px 0 15px;
      p {
        margin: 0 20px;
      }
    }
    .signup-forms {
      .form-submit-wrap {
        input {
          &.form-submit,
          &.button[type='submit'] {
            float: right;
          }
        }
      }
      .popup-offer-msg {
        float: left;
        width: 85%;
        margin: 5% 0 0 5%;
      }
      .form-messages {
        margin: 0 20px;
      }
    }
  }
}

#contact-form-wrap {
  &.personal-info-form-container {
    .second-column-wrap {
      .form_element {
        &.button_container {
          .bgdisable {
            background-color: $color-gray;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .reservation__list {
        display: inline-block;
        &-item {
          list-style-type: disc;
          &:last-child {
            color: $color-red;
          }
        }
      }
    }
  }
}

.elc-search-typeahead-count {
  display: none;
}
