#footer {
  .footer-bottom {
    .loyalty_content.hidden {
      display: none;
    }
  }
}

// Contact us Page
.contact_us {
  form {
    .header {
      .sub_contact_list {
        li {
          input[type='radio'] {
            float: left;
          }
          .label-wrap {
            margin-left: 25px;
            display: block;
          }
        }
      }
    }
    .personal-info-form-container {
      .state_province_container,
      .province_container,
      .address1_container,
      .address2_container,
      .city_container,
      .postal_code_container {
        display: none;
      }
    }
  }
  .beauty_product {
    .sub_contact_list {
      .additional_information {
        display: none !important;
      }
    }
  }
}

//Gnav changes
#header {
  background-image: none !important;
  background-position-y: bottom !important;
  .gnav-search-v1 {
    .form-type-textfield {
      border-radius: 0 !important;
      box-shadow: none !important;
      border-bottom: 1px solid #000 !important;
    }
    form {
      .form-text {
        font-size: 12px !important;
      }
    }
  }
  .gnav-drop-down-v1 {
    .dd-icon {
      .link {
        font-size: 12px !important;
        color: #000 !important;
        padding-bottom: 14px !important;
        img {
          margin-bottom: -7px;
        }
      }
    }
  }
  .top-right {
    .block {
      border-left-width: 0;
    }
    .inner div:nth-child(3) {
      .gnav-drop-down-v1 {
        .dd-content.active {
          display: none;
        }
      }
    }
  }
  .top-right.column {
    .inner {
      border-right: none;
    }
  }
}

#navigation {
  background-color: #fafafa !important;
}

.mm-formatter-v1 {
  .mm-menu li {
    border-left: none !important;
    border-right: none !important;
  }
}

#sticky-bar {
  .mm-menu li {
    border-left: none !important;
    border-right: none !important;
  }
}

.offer-promo-carousel-v1 {
  width: 500px;
}

.spp_reviews {
  &.bv-widgets {
    display: none;
  }
}

.mpp-grid {
  .mpp-box {
    &:hover {
      .mpp-product {
        display: none;
      }
    }
  }
}

.adv_cal_cap {
  .adv-cal-cap-container {
    .adv-cal-cap-popup {
      .popup-content-wrapper {
        .offer-details__controls {
          .btn-wrapper {
            .button {
              float: $rdirection;
              margin-top: 0;
              width: 48%;
              background-color: $color-white;
              color: $color-black;
              :hover {
                background-color: $color-white;
                color: $color-black;
              }
            }
            .button:nth-child(even) {
              float: $ldirection;
              width: 48%;
              color: $color-white;
              background-color: $color-black;
              padding: 0;
              :hover {
                background-color: $color-black;
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}

html[lang='zh-hk'] {
  .header-gnav-navigation {
    .text-link--style-2-bold {
      font-weight: 700;
    }
  }
}

.header-gnav-navigation {
  a[href='#'] {
    text-decoration: none;
  }
}
